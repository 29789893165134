<template>
    <div class="pay_detail_box">
        123
    </div>
</template>

<script>
import { payDetail } from '@/api/login.js';
export default {
    name: '',
    props: {},
    data() {
        return {};
    },
    components: {},
    created() {},
    mounted() {},
    methods: {
        payDetail() {
            let data = {
                param: {},
            };
            payDetail(data).then((res) => {
                // console.log(res);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.pay_detail_box {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: #fff;
}
</style>
